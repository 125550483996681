<template>
  <div id="shop-page">
    <ComingSoon v-if="!showShop" />
    <div class="md:mt-6" :class="`${this.$store.state.eCommerce.showAlertHeader ? '' : 'mt-0'}`"
      style="margin-top: 46px;" v-else>
      <div class="mb-10 md:mb-0">
        <div class=" category-text w-full text-left"
          v-html="brandDetails.description ? brandDetails.description : ''" />
      </div>
      <vs-breadcrumb class="block md:hidden" :items="populateBreadcrumbs" separator="chevron_right">
      </vs-breadcrumb>

      <vs-breadcrumb class="hidden md:block mb-10" :items="populateBreadcrumbs" separator="chevron_right">
      </vs-breadcrumb>

      <div v-if="brandsComponents.length && getFlag()" class=" mb-10">
        <div v-for="(module, index) in brandsComponents" :key="index">
          <Carousel v-if="module.module === 'carousel'" :elements="module.elements" />
          <div v-if="module.module === 'text'">
            <div class="text-module m-5" v-html="module.html" />
          </div>
          <Banner v-if="module.module === 'banner'" :elements="module.elements" />
          <Video v-if="module.module === 'video'" :element="module" />
          <div v-if="module.module === 'products'">
            <h3 class="title pt-12 pb-4">{{ module.title }}</h3>
            <builder-products :product_ids="module.elements" :isPreviewPage="isPreviewPage()" />
          </div>
        </div>
      </div>

      <side-bar-category-filter class=" flex md:hidden mb-2" :categories="categories" :isSelected="isSelected"
        :updateSelectedFilters="updateSelectedFilters" />
      <div v-if="selectedFilters.length > 0" class="block md:hidden my-4">
        Selected Filters{{ `(${this.selectedFilters.length})` }}
      </div>
      <div>
        <h1>{{ this.$isAuRegion() ? "Products" : "Shop Products" }}</h1>
      </div>
     
      <div v-if="brandDetails.desktopImage" class="mb-6 w-full text-center hidden items-center sm:flex md:mt-16">
        <div class=" w-full text-center ">
          <img :src="brandDetails.desktopImage" class=" max-w-full" />
        </div>
      </div>

      <div v-if="brandDetails.mobileImage" class=" w-full text-center flex items-center sm:hidden md:mt-16">
        <div class=" w-full text-center max-w-full p-5">
          <img :src="brandDetails.mobileImage" class=" max-w-full" />
        </div>
      </div>
      <div id="products" class="flex">
        <div v-if="categories.length > 0" class="md:block hidden pr-8" style="width: 300px;">
          <div v-if="categories.length > 0" class="text-2xl text-black font-semibold mb-2">Categories</div>
          <div :key="item._id" v-for="(item) in categories">
            <vs-checkbox class="ml-0 text-lg font-normal my-2" :vs-value="item._id" :checked="isSelected(item._id)"
              @click="updateSelectedFilters(item._id)">{{ item.name }}</vs-checkbox>
          </div>
        </div>
        <div class="w-screen">
          <div class="flex items-center justify-between mb-5 text-xl">
            <div v-if="filteredItems.length > 0" class="hidden md:flex gap-3">
              {{ filteredItems.length }}
              <div v-if="filteredItems.length > 1">
                products
              </div>
              <div v-else>
                product
              </div>
            </div>
          </div>
          <div>
            <div v-if="paginatedItems.length > 0" class="grid grid-cols-2 lg:grid-cols-4 lg:gap-4 items-wrapper pt-1">
              <div v-for="(item, index) in paginatedItems" :key="item.objectID" class="
                  items-list-view
                  mb-base
                  w-full
                  md:p-2
                  lg:py-0
              ">
                <item-list-view :item="item" :brandDetails="brandDetails" :index="index"
                  :inCart="isInCartTest(item._id) && !isProductScript(item) && !isProductBundle(item)"
                  :superAdminCanAddToCart="superAdminCanAddToCart" @quantityChange="quantityChange">
                  <template slot="action-buttons">
                    <div class="w-full"
                      v-if="!isInCartTest(item._id) || isProductScript(item) || isProductBundle(item)">

                      <div class="
                          item-view-secondary-action-btn
                          bg-primary
                          w-full
                          rounded-lg
                          py-3
                          items-center
                          justify-center
                          text-white
                          cursor-pointer
                          text-center
                        " @click="cartButtonClicked(item)"
                        v-if="((item.canBuy && item.isMatchS4Medication) || superAdminCanAddToCart) && item.isAvailable">
                        <span v-if="item.type === 'lead'" class="text-base font-base">
                          View Product
                        </span>
                        <span v-else class="text-base font-base">
                          Add to Cart
                        </span>
                      </div>
                    </div>

                    <div v-if="!item.canBuy && !superAdminCanAddToCart" class="w-full">
                      <div class="
                          item-view-secondary-action-btn
                          bg-primary
                          w-full
                          py-3
                          rounded-lg
                          items-center
                          justify-center
                          text-white
                          cursor-pointer
                          text-center
                        " @click="learnMore(item.supplier.supplierOnboardingLink)"
                        v-if="item.supplier.supplierOnboardingLink && item.isMatchS4Medication">
                        <span class="text-base font-base">Learn More</span>
                      </div>
                      <div class="
                          item-view-secondary-action-btn
                          bg-primary
                          w-full
                          py-3
                          rounded-lg
                          items-center
                          justify-center
                          text-white
                          cursor-pointer
                          text-center
                        " @click="popupActive = true" v-else>
                        <span class="text-base font-base">Contact Fresh</span>
                      </div>
                    </div>

                    <div v-if="!isInCartTest(item._id) && item.canBuy && !superAdminCanAddToCart && !item.isMatchS4Medication" class="w-full">
                      <div class="
                        item-view-secondary-action-btn
                        bg-primary
                        w-full
                        py-3
                        rounded-lg
                        items-center
                        justify-center
                        text-white
                        cursor-pointer
                        text-center
                      " @click="popupActive = true">
                        <span class="text-base font-base">Contact Fresh</span>
                      </div>
                    </div>

                    <div v-if="!item.isAvailable && item.canBuy && item.isMatchS4Medication" class="w-full">
                      <div class="
                          item-view-secondary-action-btn
                          bg-gray-400
                          w-full
                          py-3
                          rounded-lg
                          items-center
                          justify-center
                          text-white
                          cursor-pointer
                          text-center
                        ">
                        <span class="text-sm font-base">Out of Stock</span>
                      </div>
                    </div>
                  </template>
                </item-list-view>

              </div>
            </div>
            <div v-else-if="paginatedItems.length <= 0 && !isLoading" class="w-full flex justify-center">
              <div class=" text-center">
                <img style="width: 150px;" src="../../../assets/images/no-product-found.png" />
                <h2>
                  Sorry, no products found!
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <vs-popup class="holamundo" title="Supplier Account Required" :active.sync="popupActive">
        <div class="flex flex-no-wrap">
          <div class="w-1/6 flex items-center justify-center">
            <vs-icon icon="clear" size="medium" bg="#f9d6d6" color="#b21f1e" round></vs-icon>
          </div>
          <div class="w-5/6">
            <ContactFresh :email="contactEmail" :number="contactNumber" />
          </div>
        </div>
        <div class="flex justify-end mt-5">
          <vs-button class="mr-4 mb-4" @click="popupActive = false">
            I understand
          </vs-button>
        </div>
      </vs-popup>

      <paginate class="pagination bg-transparent mt-10 mb-6" v-if="totalPages > 0" v-model="currentPage"
        :page-count="totalPages" :click-handler="(ret) => goToPage(ret, true)" :prev-text="'<'" :next-text="'>'"
        :page-range="4" />

    </div>
  </div>
</template>


<script>
import { mapActions } from "vuex";
import _ from "lodash";
import ClinicSwap from "../../components/shop/clinic.vue";
import LDClient from "launchdarkly-js-client-sdk";
import ComingSoon from "../../clinic/ClinicShopComingSoon.vue";
import Navbar from "../../components/shop/Navbar.vue";
import ContactFresh from "../../components/ContactFresh"
import SideBarCategoryFilter from '../../components/shop/SideBarCategoryFilter.vue';
import Banner from '../../components/brands/banner.vue';
import Carousel from '../../components/brands/carousel.vue';
import Video from '../../components/brands/video.vue';
import builderProducts from "@/views/superAdmin/store/homepage/builderProducts.vue";

// Utils
import {trackEcommerceEvent, formatPriceValue, VIEW_ITEM_LIST_EVENT}from '../../../utils/gtag'


export default {
  name: "Category",
  inject: ["getRoleRouteName"],
  components: {
    ItemGridView: () =>
      import("@/views/components/order-center/ItemGridView.vue"),
    ItemListView: () => import("./ItemListView.vue"),
    ClinicSwap,
    ComingSoon,
    Navbar,
    ContactFresh,
    SideBarCategoryFilter,
    Banner,
    Carousel,
    builderProducts,
    Video
  },
  data() {
    return {
      contactEmail: this.contactFresh('email'),
      contactNumber: this.contactFresh('number'),
      slug: "",
      value: [],
      subCategories: [],
      categories: [],
      selectedFilters: [],
      brands: [],
      hasOrganization: false,
      showShop: false,
      brandDetails: {},
      popupActive: false,
      filterParams: {
        brandsOption: [],
        search: "",
        clinicId: ""
      },
      limitOptions: [25, 50, 100],
      sortItems: [
        { value: "order_asc", label: "Sort by featured", id: 1, },
        { value: "price_asc", label: "Lowest Price", id: 2, },
        { value: "price_desc", label: "Highest Price", id: 3, },
      ],
      filteredItems: [],
      productList: [],
      itemsPerPage: 20,
      currentPage: this.$route.query.page || 1,
      showSidebar: false,
      isMobileView: null,
      userType: "",
      userTypeAdmin: undefined,
      role: "",
      brandJsonDetails: [],
      canAdd: false,
      isLoading: true,
      brandsComponents: []
    }
  },
  computed: {
    populateBreadcrumbs() {
      /** get base url */
      let owner = this.$route.path.split('/')[1]
      if (this.userTypeAdmin && this.userTypeAdmin === "superAdmin")
        owner = 'super-admin/store'

      return [
        { title: 'Home', url: `/${owner}/shop` },
        {
          title: this.brandJsonDetails.length > 0
            ? this.brandJsonDetails[0].name
            : this.brandDetails.brandName,
          active: true,
        },
      ]
    },
    superAdminCanAddToCart() {
      return (this.userTypeAdmin && this.userTypeAdmin === "superAdmin") && !!this.canAdd
    },
    toValue() {
      return (value, range) => [
        value.min !== null ? value.min : range.min,
        value.max !== null ? value.max : range.max,
      ];
    },
    toggleScreenSize: function () {
      if (this.isMobileView) {
        return !this.learnMorePopupActive;
      }
      return true
    },
    paginatedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return _.slice(this.filteredItems, startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.filteredItems.length / this.itemsPerPage);
    },
    totalItemQuantityIncart() {
      return (itemId) =>
        this.$store.getters["eCommerce/totalItemQuantityIncart"](itemId);
    },
    // GRID VIEW
    isInCart() {
      return (itemId) => this.$store.getters["eCommerce/isInCart"](itemId);
    },
    isInWishList() {
      return (itemId) => this.$store.getters["eCommerce/isInWishList"](itemId);
    },
    getCartInfo() {
      return (itemId) => this.$store.getters["eCommerce/getCartItemTest"](itemId);
    },
    isInCartTest() {
      return (itemId) => this.$store.getters["eCommerce/isInCartTest"](itemId);
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    validateForm() {
      return !this.errors.any();
    },
  },
  methods: {

    ...mapActions("ecommerce", ["fetchFilterData", "getCartItemsFromApi", "productFilterListing", "getJsonSettings"]),
    ...mapActions("appUser", ["fetchAppUserIndex"]),
    ...mapActions("storeProducts", ["fetchStoreLimitation"]),
    ...mapActions("category", ["getSubCategoryList", "getCategoryDetailsBySlug"]),
    ...mapActions("brand", ["getBrandProductListBySlug", "getBrandList", "getBrandDetailsBySlug"]),
    ...mapActions("admin", ["getSettingsByKey"]),
    ...mapActions("eCommerce", ["getBrandJsonSettings"]),
    learnMore(url) {
      window.open(url)
    },
    getFlag() {
      // if route includes preview string return true
      // if not return hasCustomPage
      if (this.$route.name === "SuperAdminCustomBrandPreviewPage") {
        return true
      }
      return this.brandDetails.hasCustomPage
    },
    isPreviewPage() {
      return this.$route.name === "SuperAdminCustomBrandPreviewPage"
    },
    async fetchBrandJsonSettings() {
      try {
        const res = await this.getBrandJsonSettings({ brandId: this.brandDetails._id })
        this.brandsComponents = res.data.data.map(moduleDetail => {
          if (moduleDetail.module === "products") {
            moduleDetail.elements = moduleDetail.elements.map(element => element._id)
          }
          return moduleDetail
        })
      }
      catch (e) {
        this.brandsComponents = [];
        console.log(e)
      }
    },
    isProductScript(item) {
      return item && item.type === "script";
    },
    isProductBundle(item) {
      return item && item.type === "bundle";
    },
    async fetchCanAddSettingKey() {
      try {
        const { data } = await this.getSettingsByKey("superAdminCanAdd")

        if (data.data) this.canAdd = data.data.value === "yes"
      } catch (err) {
        console.log(' ----> error fetching can-add setting key ')
        console.log(err)
      }
    },
    async getJsonDetailsBySlug(slug) {
      const res = await this.getJsonSettings({ slug: "navigation" })
      const allJsonBrands = res.data.data.brands.all_brands
      const antiWrinkleBrands = res.data.data.brands.antiwrinkle_brands
      const fillerBrands = res.data.data.brands.filler_brands
      this.brandJsonDetails = _.filter([...allJsonBrands, ...antiWrinkleBrands, ...fillerBrands], { slug: slug })
    },
    contactFresh(contact) {
      if (process.env.VUE_APP_REGION !== 'AU') {
        if (contact === 'email') return this.contactEmail = "hello@freshclinics.com"
        if (contact === 'number') return this.contactNumber = "+1 888-925-1462"
      }

      if (contact === 'email') return this.contactEmail = "info@freshclinics.com.au"
      if (contact === 'number') return this.contactNumber = "1300 375 646"

    },
    myEventHandler() {
      this.isMobileView = window.innerWidth < 768;
    },
    clearSelectedCategory() {
      this.subCategories = this.subCategories.map(item => ({ ...item, isSelected: false }));
      this.filteredItems = this.productList
      this.goToPage(1)
    },
    onSubcategoryClose(category) {

      let index = this.subCategories.findIndex(item => item._id === category._id)
      this.subCategories[index].isSelected = !this.subCategories[index].isSelected;

      const selectedSubCat = _.filter(this.subCategories, ['isSelected', true])

      if (selectedSubCat.length > 0) {
        this.filterProducts()
      } else {
        this.filteredItems = this.productList
      }

    },

    filterProducts() {

      const selectedSubCat = _.filter(this.subCategories, ['isSelected', true]).map(sc => (sc._id));

      /** select all products with in the selected filters */
      this.filteredItems = _.filter(this.productList, function (item) {
        const commonItems = _.intersection(item.categoryId, selectedSubCat)
        return commonItems.length > 0;
      })

      this.goToPage(1)
    },

    goToPage(pageNumber, clicked = false) {
      let routerName;

      if (this.$route.name === "SuperAdminCustomBrandPreviewPage") {
        routerName = "SuperAdminCustomBrandPreviewPage";
      } else if (this.userTypeAdmin === "superAdmin") {
        routerName = "SuperAdminOrderCenterBrandPage";
      } else if (this.role === "Org Owner") {
        routerName = "OrgOwnerOrderCenterBrandPage";
      } else if (this.userType === "doctor") {
        routerName = "DoctorOrderCenterBrandPage";
      } else if (this.userType === "nurse") {
        routerName = "NurseOrderCenterBrandPage";
      } else {
        routerName = "BasicOrderCenterBrandPage";
      }

      this.$router.push({ name: routerName, query: { page: pageNumber }, hash: clicked ? "#products" : "" });
      this.currentPage = pageNumber;
    },

    quantityChange(data) {
      if (this.filteredItems[data.index].type !== 'basic' || this.filteredItems[data.index].tc) {
        this.$router.push(
          {
            name: this.getRoleRouteName("ProductDetail"),
            params: {
              id: this.filteredItems[data.index]._id,
            },
          }).catch(() => { });
        return false;
      }
      this.filteredItems[data.index].selectedQuantity = data.quantity;
    },
    async getStoreLimitiation() {
      const { data } = await this.fetchStoreLimitation();
      this.AllLimitations = data.data.docs;
    },
    async getAllBrandDetails() {
      try {

        const res = await this.getBrandDetailsBySlug(this.slug)
        this.brandDetails = res.data.data[0] || {}
        this.fetchBrandJsonSettings()

      } catch (error) {
        console.log(error)
      }
    },
    async getAllBrands() {
      try {
        const res = await this.getBrandList();
        this.brands = res.data.data.docs.map(item => ({ ...item, isSelected: false }));
        this.getAllBrandDetails();
      }
      catch (err) {
        console.log(err)
      }
    },
    sort(arr) {
      return arr.sort((a, b) => {
        if (a.orderId === null) return 1;
        if (b.orderId === null) return -1;
        return a.sort - b.sort;
      });
    },
    async getProductsStore() {
      try {
        this.isLoading = true
        this.$vs.loading({
          container: this.$refs.loadableDiv
        })
        const res = await this.getBrandProductListBySlug(this.filterParams)
        this.filteredItems = [];
        this.productList = this.sort(res.data.data.products);
        this.filteredItems = this.sort(res.data.data.products);
        this.subCategories = res.data.data.subCategories.map(item => ({ ...item, isSelected: false }));
        this.categories = res.data.data.categories
        this.$vs.loading.close(this.$refs.loadableDiv)
        this.$vs.loading.close()
        this.isLoading = false
        if (this.$route.query.page) {
          this.goToPage(this.$route.query.page);
        } else {
          this.goToPage(1);
        }
      } catch (err) {
        console.log(err);
        this.$vs.loading.close(this.$refs.loadableDiv)
        this.$vs.loading.close()
        this.isLoading = false

      }
    },
    async cartButtonClicked(item) {
      if (item.tcc || item.type === 'bundle' || item.type === 'lead' || item.type === 'script') {
        this.$router
          .push({
            name: this.getRoleRouteName("ProductDetail"),
            params: {
              id: item._id,
            },
          })
          .catch(() => { });
        return false;
      }

      let isValid = await this.$validator.validateAll("basic");
      if (!isValid) {
        this.$vs.notify({
          title: "Error",
          text: "Validation Failed",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return false;
      }
      if (this.$store.state.clinicId === null) {
        this.$vs.notify({
          title: "Failed",
          text:
            "Please Select a clinic.",
          color: "danger",
        });
        return false;
      }
      if (item.canAdd === false) {
        this.$vs.notify({
          title: "Failed",
          text:
            "Selected quantity must not be less than " +
            item.inventory.requiredQuantity,
          color: "danger",
        });
        return false;
      }
      const cartQuantity = this.totalItemQuantityIncart(item._id);
      if (item.selectedQuantity === 0) {
        this.$vs.notify({
          title: "Failed",
          text: "Quantity cannot be zero",
          color: "danger",
        });
        return false;
      }
      if (item.isAvailable !== true) {
        this.$vs.notify({
          title: "Failed",
          text: "Product is not available right now.",
          color: "danger",
        });
        return false;
      }

      const totalCartAndSelectedQty = (cartQuantity + item.selectedQuantity)
      if (item.inventory.maxQuantity !== null && item.inventory.maxQuantity !== 0) {
        if (item.selectedQuantity > item.inventory.maxQuantity || totalCartAndSelectedQty > item.inventory.maxQuantity) {
          this.$vs.notify({
            title: "Failed",
            text: "You've exceeded maximum order amount.",
            color: "danger",
          });
          return false;
        }
      }

      this.additemInCart(item, 'increment');
    },

    additemInCart(data, type) {
      let minQty = 1;
      if (data.inventory.requiredQuantity > 1) {
        minQty = data.inventory.requiredQuantity
      }
      let item = { data: data, type: type, minQty: minQty }

      this.$vs.loading();

      item.data.shipping.shippingFee = item.data.supplier.deliveryDetails ? item.data.supplier.deliveryDetails.deliveryFee : 0;

      this.$store.dispatch("eCommerce/additemInCart", item).then((response) => {
        if (response.status === 200 && response.data.message === 'success') {
          this.getCartItemsFromApi()
          this.$vs.notify({
            color: 'success',
            title: 'Product added',
            text: 'Product was successfully added to your cart.'
          })
        } else {
          this.$vs.notify({
            color: 'danger',
            title: `Product Not Updated`,
            text: `We are having issues updating your cart. Please try again.`
          })
        }

        this.$vs.loading.close();
      });
    },
    isSelected(itemId) {
      return this.selectedFilters.includes(itemId);
    },
    updateSelectedFilters(itemId) {
      const index = this.selectedFilters.indexOf(itemId);
      if (index === -1) {
        this.selectedFilters.push(itemId);
      } else {
        this.selectedFilters.splice(index, 1);
      }
      if (this.selectedFilters.length === 0) {
        return this.filteredItems = this.productList
      }
      this.filteredItems = _.filter(this.productList, (o) => { return this.selectedFilters.some(categoryId => o.categoryId.includes(categoryId)) })
    }
  },
  beforeMount: async function () {
    const { email } = this.$store.state.AppActiveUser;
    const userIndex = await this.fetchAppUserIndex();
    this.getStoreLimitiation()
    const key = userIndex.data.data.count;
    const user = {
      email,
      key,
    };
    this.ldClient = LDClient.initialize(process.env.VUE_APP_LDCLIENT_KEY, user);
    this.ldClient.on("ready", this.onLaunchDarklyReady);
    this.ldClient.on("change", this.onLaunchDarklyReady);

    this.hasOrganization = localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
    );
  },
  watch: {
    subCategories: {
      handler(newArr, oldArr) {
        /** only filter if there is a slected value */
        if (_.some(newArr, (obj) => obj.isSelected)) {
          this.filterProducts()
        }
      },
      deep: true
    },
    "$route.params.slug": function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.getJsonDetailsBySlug(this.$route.params.slug);
        if (newVal) {
          this.slug = newVal
          this.filterParams.brandsOption = [this.$route.params.slug]
          this.getJsonDetailsBySlug(this.$route.params.slug);
          this.getAllBrandDetails()
          this.getProductsStore();
          if (this.$route.query) {
            this.goToPage(this.$route.query.page);
          } else {
            this.goToPage(1);
          }
        }
        // else {
        //   this.$router.push("shop")
        // }
      }
    },
    "$store.state.clinicId": function (val) {
      this.filterParams.clinicId = val
      this.getProductsStore();
      this.getTreeCategories();
    },
    "$store.state.showStore": function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.showShop = this.$store.state.showStore;
      }
    },
    "$route.query": function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.currentPage = newVal.page
      }
    }
  },
  created() {
    const clinicId = sessionStorage.getItem(`doctorClinicSelectedClinic`)
    this.slug = this.$route.params.slug;
    this.getJsonDetailsBySlug(this.$route.params.slug);
    this.filterParams.brandsOption = [this.slug];
    this.filterParams.clinicId = clinicId;
    this.getAllBrands();
    // this.getAllBrandDetails();
    this.getProductsStore();
    /** rest to page */
    // this.goToPage(1)
    this.userType = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    ).userType;

    this.role = localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
    );

      this.userTypeAdmin = this.$store.state.AppActiveUser.userType
      this.showShop = this.userTypeAdmin === 'superAdmin' ? true : this.$store.state.showStore;
    
    trackEcommerceEvent(VIEW_ITEM_LIST_EVENT, {
      item_list_id: "brand",
      item_list_name: "Brand",
      items: this.paginatedItems.map((item) => {
        return {
          item_id: item._id,
          item_name: item.name,
          price: formatPriceValue(item.price),
          quantity: item.quantity
        };
      }),
    });

    this.userTypeAdmin = this.$store.state.AppActiveUser.userType
    this.showShop = this.userTypeAdmin === 'superAdmin' ? true : this.$store.state.showStore;

    if (this.userTypeAdmin && this.userTypeAdmin === "superAdmin") this.fetchCanAddSettingKey()
  },
  mounted() {
    window.addEventListener("resize", this.myEventHandler);
    this.myEventHandler();
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },

}
</script>

<style lang="scss">
.text-module {
  ul {
    list-style-type: disc !important;
    list-style-position: inside !important;

    li {
      padding: 0 1.5rem;
    }
  }

  ol {
    list-style-type: decimal !important;
    list-style-position: inside !important;

    li {
      padding: 0 1.5rem;
    }
  }
}

.content-wrapper {
  background-color: white;
}

.select-filter-dd {
  background-color: #074230;
  width: 74px;
  color: #eee;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  cursor: pointer;
  letter-spacing: 2px;
  height: 34px;

  i,
  span {
    color: #eee;
  }
}

.cat-filter {
  border-bottom: solid thin #f2f2f2;
}

.banner {
  background: #f2f2f2;
}

.themed-text {
  color: #074230;
}

.category-text {
  color: #2d2d2d;
  font-weight: lighter;
}

[dir] .con-select .vs-select--input {
  border: 0 !important;
  padding: 13px;
  font-size: 1.1rem;
}

.hover-text-red-600:hover {
  color: #e53e3e;
}

.tag {
  color: #074230;
  background-color: rgba(222, 239, 234, .34);
}

.products-sidebar .vs-sidebar--header {
  background-color: #074230;
}

.products-sidebar .vs-sidebar-primary {
  max-width: 100%;
}

.products-sidebar .vs-sidebar--footer {
  height: 42px;
  background-color: #84CDB8;
}

.footer-container {
  height: 42px;

}



.items-wrapper {
  >div {
    border-left: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;

    &:first-of-type,
    &:nth-of-type(2) {
      border-top: 1px solid #eaeaea;
    }

    &:nth-of-type(even) {
      border-left: none;
      border-right: 1px solid #eaeaea;
    }

    &:nth-of-type(odd) {
      border-right: 1px solid #eaeaea;
    }
  }
}

@media (min-width: 992px) {
  .items-wrapper>div {
    border: 1px solid #eaeaea !important;
    border-radius: .3rem;
  }

}

@media (max-width: 991px) {
  .router-view {
    padding: 2rem 0 !important;
  }
}

.learnMorePopup .vs-popup {
  width: 50%;
}
</style>
